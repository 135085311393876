.spinner {
  display: block;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  width: 10%;
  max-width: 40px;
  min-width: 24px;
  animation: spinAndBlink 3s linear infinite;
}

@keyframes spinAndBlink {
  0% {
    transform: rotate(0deg);
    opacity: 1;
  }
  50% {
    transform: rotate(180deg);
    opacity: 0.5;
  }
  100% {
    transform: rotate(360deg);
    opacity: 1;
  }
}
